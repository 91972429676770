<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        If you have a
        <number-value :value="volSoln" unit="\text{mL}" />
        solution of
        <number-value :value="concHA" unit="\text{M}" />
        benzoic acid,
        <chemical-latex content="C6H5COOH," />
        and it is being titrated with
        <number-value :value="concNaOH" unit="\text{M}" />
        <chemical-latex content="NaOH," />
        answer each of the questions below. Note that the
        <stemble-latex content="$\text{K}_\text{a}$" />
        value of benzoic acid is
        <stemble-latex content="$6.40\times 10^{-5}.$" />
      </p>

      <p class="mb-2">a) How many moles of benzoic acid are present in the original solution?</p>
      <calculation-input
        v-model="inputs.molHA"
        class="mb-5"
        prepend-text="$\ce{C6H5COOH}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What is the volume of the equivalence point,
        <stemble-latex content="$\text{V}_\text{eq},$" />
        for the titration in litres,
        <stemble-latex content="$\text{L?}$" />
        <b>Hint:</b> This is the volume of NaOH added to reach the equivalence point NOT the total
        volume of the system at this point.
      </p>

      <calculation-input
        v-model="inputs.Veq"
        class="mb-5"
        prepend-text="$\text{V}_\text{eq}:$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) What is the
        <stemble-latex content="$\text{pH}$" />
        at the equivalence point?
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question165',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molHA: null,
        Veq: null,
        pH: null,
      },
    };
  },
  computed: {
    volSoln() {
      return this.taskState.getValueBySymbol('volSoln').content;
    },
    concHA() {
      return this.taskState.getValueBySymbol('concHA').content;
    },
    concNaOH() {
      return this.taskState.getValueBySymbol('concNaOH').content;
    },
  },
};
</script>
